import react, {useState} from "react";
import './index.scss'
import emailjs from 'emailjs-com';
import LogoSVG from '../../img/nuevoLogo.svg'
import LogoFooterSVG from '../../img/logoFooterSVG.svg'
import validator from 'validator'
import {useLocation} from 'react-router-dom'
const axios = require('axios')

const Flechas = require('../../img/flechas.png')
const Arriba = require('../../img/arriba.png')
const Abajo = require('../../img/abajo.png')
const Home = () =>{

    const query = new URLSearchParams(useLocation().search);
    let utm_source =query.get('utm_source')
    let utm_medium =query.get('utm_medium')
    let utm_campaign =query.get('utm_campaign')

    const[text,setText] = useState('ELIJE LAS ZONAS DE TU INTERES ')
    const[zonatext, setZonatext] = useState('')
    const[seleccionUno,setSeleccionUno] = useState('pointOne')
    const[seleccionDos,setSeleccionDos] = useState('pointTwo')
    const[seleccionTres,setSeleccionTres] = useState('pointThree')
    const[seleccionCuatro,setSeleccionCuatro] = useState('pointFour')
    const[seleccionCinco,setSeleccionCinco] = useState('pointFive')
    const[seleccionSeis,setSeleccionSeis] = useState('pointSix')
    const[seleccionSiete,setSeleccionSiete] = useState('pointSeven')
    const[seleccionOcho,setSeleccionOcho] = useState('pointEight')
    const[estadoSeleccion, setEstadoSeleccion] = useState('0')
    

    const frmContact ={
        reply_to:'vladi_ortega@hotmail.com', 
        nombre:'',
        telefono:'', 
        correo:'',     
        zona:text, 
    }
    const [contact,setContact] = useState(frmContact);
    const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };

    const handleSubmit = e =>{
        alert("entro")
	    e.preventDefault();
     
		emailjs.send('default_service','template_ammjr2i', contact,'akEUA8UHaQ1B4AlAf')
		.then((response) => {
				   console.log('SUCCESS!', response.status, response.text);
				   setContact(frmContact);
                   window.location.reload()
                    setTimeout(function(){
                        window.location.reload()
                    }, 1000);
           
		}, (err) => {
				   console.log('FAILED...', err);
		});
   }



   function EnviarFormulario(){

    
    if(contact.nombre ===''){
        alert("Debe de ingresar un nombre !")
    }else if(contact.telefono ===''){
        alert("Debe de ingresar un Telefono !")
    }else if(contact.correo ===''){
        alert("Debe de ingresar un Correo !")
    }else if(zonatext === ''){
        alert("Debe de ingresar una Zona !")
    }else{
    
        if (validator.isEmail(contact.correo) && isNaN(contact.telefono)===false) {
           
            const options = {
                method: 'POST',
                url: 'https://servidor.resvera.com.mx/enviarData',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                params: {
                    
                        email: contact.correo ,
                        nombre: contact.nombre, 
                        telefono: contact.telefono,
                        zona: zonatext,
                        landing:"landing1",
                        source: utm_source,
                        medium: utm_medium,
                        campaign: utm_campaign                
                    
                }
              };


              axios.request(options)
                .then(function (response) {
                    console.log(response.data);
                    window.location.href = "http://enviado.resvera.com.mx/"
                     
                })
                .catch(function (error) {
                    console.error(error);
                });


          } else {
            
            
            alert("ingrese un correo y/o telefono correcto")
          }



    }



    }



    function changeText(id){
        if(id===1){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="LINEAS DE EXPRESIÓN";
            setText('1 - LINEAS DE EXPRESIÓN')
            setZonatext('LINEAS DE EXPRESIÓN')            
            setSeleccionUno('pointOne selected')
            setSeleccionDos('pointTwo' )
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
            setSeleccionOcho('pointEight')
        }
        if(id===2){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="MANCHAS";
            setText('2 - MANCHAS')
            setZonatext('MANCHAS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo selected')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
            setSeleccionOcho('pointEight')
        }
        if(id===3){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="OJERAS";
            setText('3 - OJERAS')
            setZonatext('OJERAS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree selected')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
            setSeleccionOcho('pointEight')
        }
        if(id===4){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="OJOS";
            setText('4 - OJOS')
            setZonatext('OJOS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour selected')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
            setSeleccionOcho('pointEight')
        }
        if(id===5){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="PAPADA";
            setText('5 - PAPADA')
            setZonatext('PAPADA')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive selected')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
            setSeleccionOcho('pointEight')
        }
        if(id===6){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="NARIZ";
            setText('6 - NARIZ')
            setZonatext('NARIZ')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix selected')
            setSeleccionSiete('pointSeven')
            setSeleccionOcho('pointEight')
        }
        if(id===7){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="LABIOS";
            setText('7 - LABIOS')
            setZonatext('LABIOS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven selected')
            setSeleccionOcho('pointEight')
        }
        if(id===8){
            setEstadoSeleccion('0')
            let Seleccion =  document.getElementById("zonaElejida");
            Seleccion.value="SURCOS";
            setText('8 - SURCOS')
            setZonatext('SURCOS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
            setSeleccionOcho('pointEight selected')
        }
    }


    return(
        <div className="generalContainerLanding">
            <section className="sectionOne">
                <div className="header">
                    <div className="footersectionOne">
                        <label className="texone">
                            Hoy date el lujo de
                            cuidarte con Resvera
                        </label>
                        
                        
                    </div>                                    
                    <div className="footersectionTwo">
                        <img src={LogoSVG}></img>
                    </div>
                </div>
              
                <div className="pointsSelection">
                    <div className={seleccionUno} onClick={()=> changeText(1)}>1</div>
                    <div className={seleccionDos} onClick={()=> changeText(2)}>2</div>
                    <div className={seleccionTres} onClick={()=> changeText(3)}>3</div>
                    <div className={seleccionCuatro} onClick={()=> changeText(4)}>4</div>
                    <div className={seleccionCinco} onClick={()=> changeText(5)}>5</div>
                    <div className={seleccionSeis} onClick={()=> changeText(6)}>6</div>
                    <div className={seleccionSiete} onClick={()=> changeText(7)}>7</div>
                    <div className={seleccionOcho} onClick={()=> changeText(8)}>8</div>
                </div>
                <div className="optionselected" >
                        {text}
                </div>
            </section>
            <div className="footersectionOne">
            </div>
            
            <section className="sectionCard">
               Recibe $1,200 en tu primer tratamiento facial
            </section>

            <section className="sectionForm">
                <form >
                    

                   


                    <div>
                        <label className="conocenos"> Conócenos y haz de tu piel tu reflejo.</label>
                    </div>
                    <div className="flechas">
                        <img  src={Flechas} />
                    </div>
                    <div className="agendacita">
                        
                        <label className="agendatucita">Agenda tu cita aquí</label>                       
                    </div>

                    <div>
                        <input type="text" placeholder="NOMBRE"  value={contact.nombre}  name="nombre" onChange={handleChange} >
                        </input>
                    </div>


                    <div>
                        <input type="tel"  placeholder="TELÉFONO" value={contact.telefono}  name="telefono" onChange={handleChange}/>
                    </div>

                    <div>
                        <input type="email"  placeholder="CORREO ELECTRÓNICO" value={contact.correo}  name="correo" onChange={handleChange} />                        
                    </div>

                    <div>

                        <div className="selectOptions">
                            <label >
                                <input type="text" className="seleccionDesplegable"  id="zonaElejida" placeholder="Elija la zona de interes" value={contact.text}  name="correo" />                        
                                {
                                    estadoSeleccion =='0'?
                                        <div className="imagen"> 
                                            <img src={Abajo} onClick={()=>setEstadoSeleccion('1')} style={{maxWidth:'50px',maxWidth:'50px'}} />
                                        </div>                                        
                                    :   
                                        <div className="imagen">
                                            <img src={Arriba} onClick={()=>setEstadoSeleccion('0')}  style={{maxWidth:'50px'}} />
                                        </div>
                                }
                            </label>
                            {
                                 estadoSeleccion =='1'?
                                 <ul>
                                 <li value="LINEAS DE EXPRESIÓN" onClick={()=> changeText(1)}><label>LINEAS DE EXPRESIÓN</label></li>
                                 <li value="MANCHAS" onClick={()=> changeText(2)}><label>MANCHAS</label></li>
                                 <li value="OJERAS" onClick={()=> changeText(3)}><label>OJERAS</label></li>
                                 <li value="OJOS" onClick={()=> changeText(4)}><label>OJOS</label></li>
                                 <li value="PAPADA" onClick={()=> changeText(5)}><label>PAPADA</label></li>
                                 <li value="NARIZ" onClick={()=> changeText(6)}><label>NARIZ</label></li>
                                 <li value="LABIOS" onClick={()=> changeText(7)}><label>LABIOS</label></li>
                                 <li value="SURCOS" onClick={()=> changeText(8)}><label>SURCOS</label></li>
                                </ul>
                                :<></>
                            }
                        
                        </div>



                    
                        <select id="zonaElejida" name="zonaElejida" style={{display:"none"}}>
                            <option value="Elija la zonas de interes" onChange={()=> changeText(1)}>Elija la zona de interes</option>
                            <option value="LINEAS DE EXPRESIÓN" onClick={()=> changeText(1)}>LINEAS DE EXPRESIÓN</option>
                            <option value="MANCHAS" onClick={()=> changeText(2)}>MANCHAS</option>
                            <option value="OJERAS" onClick={()=> changeText(3)}>OJERAS</option>
                            <option value="OJOS" onClick={()=> changeText(4)}>OJOS</option>
                            <option value="PAPADA" onClick={()=> changeText(5)}>PAPADA</option>
                            <option value="NARIZ" onClick={()=> changeText(6)}>NARIZ</option>
                            <option value="LABIOS" onClick={()=> changeText(7)}>LABIOS</option>
                            <option value="SURCOS" onClick={()=> changeText(8)}>SURCOS</option>
                        </select>
                        <label className="zonaElejidaLabel">
                           ZONA ELEGIDA
                        </label>
                    </div>




                </form>
            </section>
        
            <section className="giftcard">
                <button onClick={()=> EnviarFormulario()} >
                    Quiero mi Gift Card
                </button>
            </section>
            <section className="footer">
                <div>
                <label style={{fontStyle:"oblique"}} className='visita'>Visita nuestra Wellness Beauty Clinic </label>
                    <label><a href="https://g.page/Resvera?share" target="_blank" style={{color:"#FFF"}}>Arquímedes 198, Polanco, Miguel Hidalgo, CDMX.</a></label>
                </div>
                <div>
                    <img src={LogoFooterSVG}></img>
                </div>
            </section>
        </div>
    )
}

export default Home;







